import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IAssetsTableHookValue, useAssetsTableHook } from 'hooks/assetsTableHook';

import { ApolloError } from '@apollo/client';
import { paths } from 'constants/paths';
import { useHistory } from 'react-router-dom';
import {
  assignedTypeFilterOptions,
  dollarValueRangeOptions,
  isCurrentlyAssignedFilterOptions,
} from 'constants/selectOptions';
import { useAssetTypesTree } from 'hooks/assetTypesTreeHook';
import { useAssetLocationAll } from 'hooks/assetLocationAllHook';
import { useUI } from 'contexts/UiContext';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';
import { useAssetStatussAutocompleteDataHook } from 'hooks/assetStatusAutocompleteDataHook';
import { useMainTabsHistory } from 'hooks/mainTabsHistory';
import { IOrderByColumn } from 'components/ui/Table/Table';
import { IPageLoadParams } from 'hooks/tableDataSourceHook';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
export interface IContextState extends IAssetsTableHookValue {
  filterOptions?: any;
  filterValues?: any;
  totalItems: number;
  items: any[];
  loading: boolean;
  filterApplied: any;
  pageLoadParams: IPageLoadParams;
  error: ApolloError | undefined;
}

export interface IContextActions {
  clearAllFilters: any;
  onAssetSelect: (id: string) => boolean;
  loadPage: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  onFilterChange: (filterValues: any) => boolean;
}

const initialState: IContextState = {
  loading: false,
  items: [],
  filterApplied: {},
  pageLoadParams: { page: 0, rowsPerPage: 0 },
  totalItems: 0,
  filterOptions: {},
  error: undefined,
  loadPage: () => {},
  onFilterChange: () => false,
  clearAllFilters: () => {},
};

const ComponentContext = createContext<IContextState & Partial<IContextActions>>(initialState);

const defaultFilterValues = {
  isLoaner: { id: 'All', name: 'All' },
  isCurrentlyAssigned: { id: 'All', name: 'All' },
  dollarValue: { id: 'All', name: 'All' },
};

interface IProviderProps {
  tableStorageKey?: string;
  children: any;
}

export const Provider: FC<IProviderProps> = ({ children, tableStorageKey = 'AssetsTable' }) => {
  const { addSnackbar } = useUI();
  const history = useHistory();
  const { getBasePageInfo } = useMainTabsHistory();
  const {
    totalItems,
    items,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    error,
    clearAllFilters,
    errorItems,
    errorCount,
  } = useAssetsTableHook({ tableStorageKey });

  useEffect(() => {
    if (errorItems) {
      apolloErrorHandler(addSnackbar!)(errorItems);
    }
  }, [errorItems, addSnackbar]);

  useEffect(() => {
    if (errorCount) {
      apolloErrorHandler(addSnackbar!)(errorCount);
    }
  }, [errorCount, addSnackbar]);

  const { types: assetTypes } = useAssetTypesTree({ expandAll: true });
  const [assetStatuses, setAssetStatuses] = useState<any>([]);
  const { assetLocations } = useAssetLocationAll();

  const { items: distinctAssetStatuses } = useAssetStatussAutocompleteDataHook({ search: '' });

  useEffect(() => {
    setAssetStatuses(distinctAssetStatuses);
  }, [distinctAssetStatuses]);

  const onAssetSelect = useCallback(
    (id: string) => {
      console.log(
        'New URL onAssetSelect:',
        getBasePageInfo().url + paths.client.ASSET_DETAILS_SUB_PAGE.replace(':id', id)
      );
      history.push(getBasePageInfo().url + paths.client.ASSET_DETAILS_SUB_PAGE.replace(':id', id));
      return true;
    },
    [history, getBasePageInfo]
  );

  const filterOptions = useMemo(() => {
    return {
      isCurrentlyAssigned: [{ id: 'All', name: 'All' }, ...isCurrentlyAssignedFilterOptions],
      isLoaner: [{ id: 'All', name: 'All' }, ...assignedTypeFilterOptions],
      dollarValue: [{ id: 'All', name: 'All' }, ...dollarValueRangeOptions],
      type: [...assetTypes],
      status: [...assetStatuses],
      location: [...assetLocations],
    };
  }, [assetTypes, assetStatuses, assetLocations]);

  return (
    <ComponentContext.Provider
      value={{
        totalItems,
        items,
        loadPage,
        pageLoadParams,
        loading,
        onFilterChange,
        filterApplied,
        clearAllFilters,
        onAssetSelect,
        filterOptions,
        filterValues: pageLoadParams.filterValues || defaultFilterValues,
        error,
      }}
    >
      {children}
    </ComponentContext.Provider>
  );
};

export const useComponentContext = () => useContext(ComponentContext);
