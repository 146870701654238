import { TableCell, TableRow, TextField } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { IHeadCell } from '../TableHead/TableHead';

import { DatePicker } from '@mui/lab';
import cn from 'classnames';
import EasyAutoComplete from 'components/EasyAutoComplete';
import { HierarchyMultipleSelect } from 'components/HierarchyMultipleSelect/HierarchyMultipleSelect';
import { TYPING_TIMEOUT } from 'constants/config';
import { useEffect } from 'reactn';
import s from './style.module.scss';

import IconButton from '@mui/material/IconButton';

import EastIcon from '@mui/icons-material/East';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import WestIcon from '@mui/icons-material/West';
import { blue } from '@mui/material/colors';
import { dateVariableTypes } from 'hooks/assetsTableHook';

export interface IFiltersRowProps {
  headCells: IHeadCell[];
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => void;
  showDraggColumn?: boolean;
  stickyHeader?: boolean;
}

const dateStyle = {
  backgroundColor: '#fff',
};

export const FiltersRow: FC<IFiltersRowProps> = ({
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  showDraggColumn,
  stickyHeader,
}) => {
  const [selected, setSelected] = useState<any>(filterValues || {});
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [localSelected, setLocalSelected] = useState<{ [id: string]: any }>({});
  const [open, setOpen] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    setSelected(filterValues);
  }, [filterValues]);

  useEffect(() => {
    if (onFilterChange && selected) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setTimeout(() => {
        onFilterChange(selected);
      }, TYPING_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [selected, onFilterChange]);

  let count = -1;

  return (
    <TableRow
      style={
        stickyHeader
          ? {
              position: 'sticky',
              top: 45,
            }
          : undefined
      }
    >
      {[
        showDraggColumn ? <TableCell align="left">&nbsp;</TableCell> : undefined,
        ...headCells.map((headCell) => {
          if (headCell.filter === 'date-variable-type') {
            if (!selected[headCell.id] || !Number.isInteger(selected[headCell.id].type)) {
              selected[headCell.id] = { type: 0 };
            }
          }
          count++;
          return (
            <TableCell
              key={count.toString() + '_' + headCell.id}
              className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
            >
              {headCell.filter === 'text' ? (
                <TextField
                  autoComplete="off"
                  key={`txt-${headCell.id}`}
                  variant="outlined"
                  size="small"
                  value={selected[headCell.id] || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelected({ ...selected, [headCell.id]: e.target.value });
                  }}
                  style={{ backgroundColor: 'white' }}
                  fullWidth
                />
              ) : undefined}
              {headCell.filter === 'component' && headCell.filterComponent ? (
                <headCell.filterComponent
                  key={`dd-${headCell.id}`}
                  onSelectChange={(value: any): void => {
                    setSelected({ ...selected, [headCell.id]: value });
                  }}
                  selectedItem={selected[headCell.id]}
                  label=""
                  textFieldStyle="outlined"
                  style={{ backgroundColor: 'white' }}
                  textFieldStyling={{ margin: '0px' }}
                />
              ) : undefined}
              {headCell.filter === 'dropdown' ? (
                <EasyAutoComplete
                  key={`dd-${headCell.id}`}
                  items={filterOptions[headCell.id]}
                  selected={selected[headCell.id]}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selectedChanged={(value: any): void => {
                    setSelected({ ...selected, [headCell.id]: value });
                  }}
                  getOptionSelected={(option: any, value: any) => {
                    return (
                      (!!option.id && option.id === value.id) ||
                      (!!option.key && option.key === value.key) ||
                      (!!option.name && option.name === value.name)
                    );
                  }}
                  style={{ backgroundColor: 'white' }}
                  textFieldStyling={{ margin: '0px' }}
                />
              ) : undefined}
              {headCell.filter === 'text-dropdown' ? (
                <EasyAutoComplete
                  key={`txt-dd-${headCell.id}`}
                  label=""
                  textFieldStyle="outlined"
                  getOptionSelected={(option: any, value: any) => {
                    return (
                      (!!option.id && option.id === value.id) ||
                      (!!option.key && option.key === value.key) ||
                      (!!option.name && option.name === value.name)
                    );
                  }}
                  style={{ backgroundColor: 'white' }}
                  textFieldStyling={{ margin: '0px' }}
                  // Defaults
                  optionsLabel="name"
                  // Passed props
                  {...headCell.filterProps}
                  // Open dropdown only on arrow click
                  open={!!open[headCell.id]}
                  onOpen={(e: any) => {
                    if (e.type === 'click') {
                      setOpen({ ...open, [headCell.id]: true });
                    }
                  }}
                  onClose={() => setOpen({ ...open, [headCell.id]: false })}
                  // Text input simulation
                  inputValue={selected[headCell.id] || ''}
                  onInputChange={(...props: any) => {
                    const [, value, reason] = props;
                    if (reason === 'input') {
                      setSelected({ ...selected, [headCell.id]: value });
                      headCell.filterProps.onInputChange(...props);
                    }
                  }}
                  // Dropdown
                  selected={localSelected}
                  selectedChanged={(value: any): void => {
                    setLocalSelected({ ...localSelected, [headCell.id]: value }); // for dropdown search
                    setSelected({ ...selected, [headCell.id]: value.name }); // for table search
                  }}
                />
              ) : undefined}
              {headCell.filter === 'hierarchy-multiple' ? (
                <HierarchyMultipleSelect
                  selectMode={'leafOnly'}
                  key={`dd-${headCell.id}`}
                  items={filterOptions[headCell.id]}
                  selected={selected[headCell.id]}
                  selectedChanged={(value: any): void => {
                    setSelected({ ...selected, [headCell.id]: value });
                  }}
                />
              ) : undefined}
              {headCell.filter === 'date' ? (
                <DatePicker
                  value={selected[headCell.id] || null}
                  onChange={(date: Date | null) => {
                    setSelected({ ...selected, [headCell.id]: date });
                  }}
                  clearable
                  InputProps={{
                    autoComplete: 'off',
                    style: {
                      paddingLeft: '14px',
                      ...dateStyle,
                    },
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                />
              ) : undefined}
              {headCell.filter === 'date-variable-type' &&
                (['date', 'from', 'to'].includes(dateVariableTypes[selected[headCell.id].type]) ? (
                  <div style={{ display: 'flex', backgroundColor: 'transparent' }}>
                    <DatePicker
                      value={selected[headCell.id]?.date || null}
                      onChange={(date: Date | null) => {
                        setSelected({
                          ...selected,
                          [headCell.id]: { ...selected[headCell.id], date },
                        });
                      }}
                      clearable
                      InputProps={{
                        autoComplete: 'off',
                        style: {
                          paddingLeft: '14px',
                          ...dateStyle,
                        },
                      }}
                      renderInput={(props: any) => <TextField {...props} />}
                    />
                    <IconButton
                      size="small"
                      style={{ color: blue[500] }}
                      onClick={() => {
                        const newType = (selected[headCell.id].type + 1) % dateVariableTypes.length;
                        setSelected({
                          ...selected,
                          [headCell.id]: {
                            ...selected[headCell.id],
                            type: newType,
                            dateTo: undefined,
                          },
                        });
                      }}
                    >
                      {dateVariableTypes[selected[headCell.id].type] === 'date' ? (
                        <MyLocationIcon fontSize="small"></MyLocationIcon>
                      ) : undefined}
                      {dateVariableTypes[selected[headCell.id].type] === 'from' ? (
                        <EastIcon fontSize="small"></EastIcon>
                      ) : undefined}
                      {dateVariableTypes[selected[headCell.id].type] === 'to' ? (
                        <WestIcon fontSize="small"></WestIcon>
                      ) : undefined}
                    </IconButton>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      backgroundColor: 'transparent',
                      justifyContent: 'space-between',
                    }}
                  >
                    <DatePicker
                      key="DateFrom"
                      value={selected[headCell.id]?.date || null}
                      onChange={(date: Date | null) => {
                        setSelected({
                          ...selected,
                          [headCell.id]: { ...selected[headCell.id], date },
                        });
                      }}
                      clearable
                      InputProps={{
                        autoComplete: 'off',
                        style: {
                          paddingLeft: '14px',
                          ...dateStyle,
                        },
                      }}
                      renderInput={(props: any) => <TextField {...props} />}
                    />
                    <div style={{ padding: '0 10px' }}>-</div>
                    <DatePicker
                      key="DateTo"
                      value={selected[headCell.id]?.dateTo || null}
                      onChange={(date: Date | null) => {
                        setSelected({
                          ...selected,
                          [headCell.id]: { ...selected[headCell.id], dateTo: date },
                        });
                      }}
                      clearable
                      InputProps={{
                        autoComplete: 'off',
                        style: {
                          paddingLeft: '14px',
                          ...dateStyle,
                        },
                      }}
                      renderInput={(props: any) => <TextField {...props} />}
                    />
                    <IconButton
                      size="small"
                      style={{ color: blue[500] }}
                      onClick={() => {
                        const newType = (selected[headCell.id].type + 1) % dateVariableTypes.length;
                        setSelected({
                          ...selected,
                          [headCell.id]: {
                            ...selected[headCell.id],
                            type: newType,
                            dateTo: undefined,
                          },
                        });
                      }}
                    >
                      <SyncAltIcon fontSize="small"></SyncAltIcon>
                    </IconButton>
                  </div>
                ))}
            </TableCell>
          );
        }),
      ]}
    </TableRow>
  );
};
