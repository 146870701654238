import { TableSortLabel } from '@mui/material';
import cx from 'classnames';
import { FC, useCallback } from 'react';
import styles from './style.module.scss';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface IHeaderCell {
  label: string;
  id?: string;
  sortable?: boolean;
  sort?: SortOrder;
  onSortChange?: (newSortOrder: SortOrder, id?: string) => void;
}

export const HeaderCell: FC<IHeaderCell> = ({ label, id, sortable, sort, onSortChange }) => {
  const headerClass = cx(styles.header, {
    [styles.sortable]: sortable,
    [styles.nonsortable]: !sortable,
  });

  const _sort = sort ? (sort.toLowerCase() as 'asc' | 'desc') : 'asc';

  const _onSortChange = useCallback(() => {
    const changeTo = sort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;

    onSortChange && onSortChange(changeTo, id);
  }, [id, onSortChange, sort]);

  if (sortable) {
    return (
      <div className={headerClass}>
        <TableSortLabel
          active={Boolean(sort)}
          direction={_sort}
          onClick={debounce(_onSortChange, DEBOUNCE_TIMEOUT)}
        >
          {<div className={styles.headerText}>{label}</div>}
        </TableSortLabel>
      </div>
    );
  }

  return (
    <div className={headerClass}>
      <span className={styles.headerText}>{label}</span>
      <div className="MuiSvgIcon-root" style={{ width: '8px' }}></div>
    </div>
  );
};
