import { useTableStorage } from 'components/ui/TableDnd/storage/tableStorageHook';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { IAssetAssignment } from 'graphql/ams/types/IAssetAssignment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IPageLoadParams } from './tableDataSourceHook';
import { IOrderByColumn } from 'components/ui/Table/Table';

export interface IUseAssetAssigneesProps {
  assignments: IAssetAssignment[];
}

export const useAssetAssignees = ({ assignments }: IUseAssetAssigneesProps) => {
  const { setItem, getItem } = useTableStorage({
    key: 'AssetAssignmentsList',
  });

  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: getItem().rowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: {},
  });

  useEffect(() => {
    const oldValue = getItem().rowsPerPage;
    if (pageLoadParams.rowsPerPage !== oldValue) {
      setItem({ rowsPerPage: pageLoadParams.rowsPerPage });
    }
  }, [pageLoadParams.rowsPerPage, setItem, getItem]);

  const visibleAssignments = useMemo(() => {
    const { rowsPerPage, page } = pageLoadParams;
    const from = page * rowsPerPage;
    const toNotIncluded = from + rowsPerPage;
    return [...assignments]
      .sort((a, b) => {
        if (!a.endDate) {
          return -1;
        }
        if (!b.endDate) {
          return 1;
        }
        if (a.endDate === b.endDate) {
          return 0;
        }
        if (a.endDate > b.endDate) {
          return -1;
        }
        return 1;
      })
      .slice(from, toNotIncluded);
  }, [pageLoadParams, assignments]);

  const totalItems = useMemo(() => {
    return assignments?.length || 0;
  }, [assignments]);

  const onFilterChange = useCallback(() => false, []);
  const loadPage = useCallback(
    (orderByColumns: IOrderByColumn[] | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        orderByColumns,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  return {
    assignments: visibleAssignments,
    totalItems,
    onFilterChange,
    loadPage,
    pageLoadParams,
  };
};
