import { TableCell, TableRow, TextField } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { IHeadCell } from '../TableHead/TableHead';

import { DatePicker } from '@mui/lab';
import cn from 'classnames';
import EasyAutoComplete from 'components/EasyAutoComplete';
import { TYPING_TIMEOUT } from 'constants/config';
import { useEffect } from 'reactn';
import s from './style.module.scss';

export interface IFiltersRowProps {
  headCells: IHeadCell[];
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => void;
  showDraggColumn?: boolean;
  stickyHeader?: boolean;
}

const dateStyle = {
  backgroundColor: '#fff',
};

export const FiltersRow: FC<IFiltersRowProps> = ({
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  showDraggColumn,
  stickyHeader,
}) => {
  const [selected, setSelected] = useState<any>(filterValues || {});
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setSelected(filterValues);
  }, [filterValues]);

  useEffect(() => {
    if (onFilterChange && selected) {
      if (timer.current) {
        clearInterval(timer.current);
      }
      timer.current = setTimeout(() => {
        onFilterChange(selected);
      }, TYPING_TIMEOUT);
    }
    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, [selected, onFilterChange]);

  let count = -1;
  return (
    <TableRow
      style={
        stickyHeader
          ? {
              position: 'sticky',
              top: 45,
            }
          : undefined
      }
    >
      {[
        showDraggColumn ? <TableCell align="left">&nbsp;</TableCell> : undefined,
        ...headCells.map((headCell) => {
          count++;
          return (
            <TableCell
              key={count.toString() + '_' + headCell.id}
              className={cn({ [s.paddingHalf]: headCell.padding === 'half' })}
            >
              {headCell.filter === 'text' ? (
                <TextField
                  autoComplete="off"
                  key={`txt-${headCell.id}`}
                  variant="outlined"
                  size="small"
                  value={selected[headCell.id] || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSelected({ ...selected, [headCell.id]: e.target.value });
                  }}
                  style={{ backgroundColor: 'white' }}
                  fullWidth
                />
              ) : undefined}
              {headCell.filter === 'component' && headCell.filterComponent ? (
                <headCell.filterComponent
                  key={`dd-${headCell.id}`}
                  onSelectChange={(value: any): void => {
                    setSelected({ ...selected, [headCell.id]: value });
                  }}
                  selectedItem={selected[headCell.id]}
                  label=""
                  textFieldStyle="outlined"
                  style={{ backgroundColor: 'white' }}
                  textFieldStyling={{ margin: '0px' }}
                />
              ) : undefined}
              {headCell.filter === 'dropdown' ? (
                <EasyAutoComplete
                  key={`dd-${headCell.id}`}
                  items={filterOptions[headCell.id]}
                  selected={selected[headCell.id]}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selectedChanged={(value: any): void => {
                    setSelected({ ...selected, [headCell.id]: value });
                  }}
                  getOptionSelected={(option: any, value: any) => {
                    return (
                      (!!option.id && option.id === value.id) ||
                      (!!option.key && option.key === value.key) ||
                      (!!option.name && option.name === value.name)
                    );
                  }}
                  style={{ backgroundColor: 'white' }}
                  textFieldStyling={{ margin: '0px' }}
                />
              ) : undefined}
              {headCell.filter === 'date' ? (
                <DatePicker
                  value={selected[headCell.id] || null}
                  onChange={(date: Date | null) => {
                    setSelected({ ...selected, [headCell.id]: date });
                  }}
                  clearable
                  InputProps={{
                    autoComplete: 'off',
                    style: {
                      paddingLeft: '14px',
                      ...dateStyle,
                    },
                  }}
                  renderInput={(props: any) => <TextField {...props} />}
                />
              ) : undefined}
            </TableCell>
          );
        }),
      ]}
    </TableRow>
  );
};
