import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import cn from 'classnames';
import { DEFAULT_ROWS_PER_PAGE, TABLE_PAGINATION } from 'constants/config';
import { forEach } from 'lodash';
import React, { FC, ReactElement, useEffect } from 'react';
import { useCallback } from 'reactn';
import { FiltersRow } from './components/FiltersRow/FiltersRow';
import { SortOrder } from './components/HeaderCell/HeaderCell';
import { IPagination, Pagination } from './components/Pagination/Paginations';
import { IHeadCell, TableHead } from './components/TableHead/TableHead';
import s from './style.module.scss';

import {
  West as ArrowLeftIcon,
  East as ArrowRightIcon,
  ExpandLess as ExpandLessIcon,
  AccountTree as ExpandMoreIcon,
} from '@mui/icons-material';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';

const MAX_SORT_COLUMNS = 5;

export interface IOrderByColumn {
  order: SortOrder;
  orderBy: string;
}

export interface ITableProps {
  headCells: IHeadCell[];
  dataCells: Array<any>;
  handleSelect?: (id: string) => boolean;
  totalItems: number;
  totalRecords?: number;
  loadPage?: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  filterOptions: any;
  filterValues?: any;
  onFilterChange?: (filterValues: any) => boolean;
  initRowsPerPage?: number;
  hideFilters?: boolean;
  printView?: boolean;
  initOrderByColumns?: IOrderByColumn[];
  initPage?: number;
  paginationProps?: Partial<IPagination>;
  hidePagination?: boolean;
  actionsDataCell?: {
    function: (
      data: any,
      row?: any,
      order?: SortOrder,
      sorted?: boolean
    ) => ReactElement<any, any> | null;
  };
  editDataRow?: {
    function: (data: any) => ReactElement<any, any> | null;
  };
  isEditRow?: (data: any) => boolean;
  draggable?: boolean;
  disableDrag?: boolean;
  onDragEnd?: (sourceIndex: number, destinationIndex: number) => void;

  isExpanded?: (data: any) => boolean | null;
  onExpandChange?: (id: string) => void;

  showArrowRight?: (data: any) => boolean;
  onArrowRight?: (id: string) => void;

  showArrowLeft?: (data: any) => boolean;
  onArrowLeft?: (id: string) => void;
  paginationSideComponent?: any;
  stickyHeader?: boolean;
  maxHeight?: string;
}

export const Table: FC<ITableProps> = ({
  loadPage,
  totalItems,
  totalRecords,
  handleSelect,
  dataCells,
  headCells,
  filterOptions,
  filterValues,
  onFilterChange,
  initRowsPerPage,
  hideFilters,
  hidePagination,
  printView,
  initOrderByColumns,
  initPage,
  paginationProps,
  actionsDataCell,
  editDataRow,
  isEditRow,
  disableDrag,
  draggable,
  onDragEnd,
  isExpanded,
  onExpandChange,
  showArrowRight,
  onArrowRight,
  showArrowLeft,
  onArrowLeft,
  paginationSideComponent,
  stickyHeader,
  maxHeight,
}) => {
  const [orderByColumns, setOrderByColumns] = React.useState<IOrderByColumn[] | undefined>(
    initOrderByColumns ? [...initOrderByColumns] : undefined
  );
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(initPage || 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initRowsPerPage || DEFAULT_ROWS_PER_PAGE);

  useEffect(() => {
    if (initPage !== undefined) {
      setPage(initPage);
    }
  }, [initPage]);

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = useCallback(
    (filterValues: any) => {
      if (onFilterChange!(filterValues)) {
        setPage(0);
      }
    },
    [onFilterChange]
  );

  const refresh = useCallback(() => {
    if (loadPage) {
      loadPage(orderByColumns, page, rowsPerPage);
    }
  }, [orderByColumns, page, rowsPerPage, loadPage]);

  useEffect(() => {
    refresh();
  }, [page, rowsPerPage, refresh]);

  const handleRequestSetSort = useCallback(
    (x: { sort: SortOrder; sortOrder: number } | undefined, property: string) => {
      setOrderByColumns((old: IOrderByColumn[] | undefined) => {
        if (x && x?.sortOrder < 0) {
          return undefined;
        }

        if (!old) {
          if (x) {
            return [{ order: x.sort, orderBy: property }];
          }
          return;
        }

        const newList = [...old];
        const index = old.findIndex((sort) => sort.orderBy === property);
        if (index >= 0) {
          newList.splice(index, 1);
        }
        if (!x) {
          return newList;
        }

        if (x.sortOrder > newList.length) {
          newList.push({ order: x.sort, orderBy: property });
        } else {
          newList.splice(x.sortOrder - 1, 0, { order: x.sort, orderBy: property });
        }

        if (newList.length > MAX_SORT_COLUMNS) {
          return newList.slice(0, MAX_SORT_COLUMNS);
        }

        return newList;
      });
    },
    []
  );

  const handleRequestSort = useCallback((event: React.MouseEvent<unknown>, property: string) => {
    setOrderByColumns((old: IOrderByColumn[] | undefined) => {
      if (!old) {
        return [{ order: SortOrder.ASC, orderBy: property }];
      }

      const index = old.findIndex((sort) => sort.orderBy === property);
      if (index < 0) {
        if (old.length < MAX_SORT_COLUMNS) {
          return [...old, { order: SortOrder.ASC, orderBy: property }];
        }
        return [...old.slice(0, -1), { order: SortOrder.ASC, orderBy: property }];
      }

      const { order } = old[index];
      const newList = [...old];
      if (order === SortOrder.DESC) {
        newList.splice(index, 1);
      } else {
        newList[index].order = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      }
      return newList;
    });
  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    event.preventDefault();
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      if (!handleSelect || !handleSelect(name)) newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleOnExpandChange = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onExpandChange && onExpandChange(id);
  };

  const handleOnArrowRight = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onArrowRight && onArrowRight(id);
  };

  const handleOnArrowLeft = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();

    onArrowLeft && onArrowLeft(id);
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight }}>
        <MuiTable stickyHeader={stickyHeader}>
          <MuiTableHead>
            <TableHead
              headCells={headCells}
              onRequestSort={handleRequestSort}
              onRequestSetSort={handleRequestSetSort}
              orderByColumns={orderByColumns}
              printView={printView}
              showDraggColumn={draggable}
              stickyHeader={stickyHeader}
            ></TableHead>
            {!hideFilters ? (
              <FiltersRow
                headCells={headCells}
                filterOptions={filterOptions}
                filterValues={filterValues}
                onFilterChange={handleFilterChange}
                showDraggColumn={draggable}
                stickyHeader={stickyHeader}
              ></FiltersRow>
            ) : undefined}
          </MuiTableHead>
          <TableBody>
            {dataCells?.map((row, index) => {
              const isItemSelected = isSelected(row.id?.toString());
              const columns: any[] = [];
              let count = 0;
              let colSpan = 0;

              const isExpandedRow = isExpanded ? isExpanded(row) : null;
              const toShowArrowRight = showArrowRight ? showArrowRight(row) : null;
              const toShowArrowLeft = showArrowLeft ? showArrowLeft(row) : null;

              if (isEditRow && isEditRow(row)) {
                columns.push(
                  <TableCell
                    component="th"
                    id={`tc-${index}`}
                    scope="row"
                    padding="none"
                    key={'EditCell'}
                    colSpan={headCells.length - 1 + (draggable ? 1 : 0)}
                  >
                    {editDataRow?.function ? (
                      <editDataRow.function data={row}></editDataRow.function>
                    ) : undefined}
                  </TableCell>
                );
                columns.push(
                  <TableCell
                    component="th"
                    id={`tc-${index}`}
                    scope="row"
                    padding="none"
                    key={'ActionsCell'}
                    colSpan={headCells.length - 1}
                  >
                    {actionsDataCell?.function ? (
                      <actionsDataCell.function data={row}></actionsDataCell.function>
                    ) : undefined}
                  </TableCell>
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {columns}
                  </TableRow>
                );
              } else {
                forEach(headCells, (headCell) => {
                  const index =
                    orderByColumns?.findIndex(
                      ({ orderBy }) => orderBy === headCell.sortBy || orderBy === headCell.id
                    ) ?? -1;
                  const sorted = index >= 0;
                  const { order } =
                    orderByColumns && sorted ? orderByColumns[index] : { order: SortOrder.ASC };

                  count++;
                  if (colSpan > 1) {
                    colSpan--;
                    return undefined;
                  }
                  if (headCell.colSpan && headCell.colSpan > 1) {
                    colSpan = headCell.colSpan;
                  }
                  const cellClassName = cn({
                    [s.nowrap]: headCell.dataCell?.wrap === 'nowrap',
                    [s.box]: headCell.dataCell?.wrap === 'box',
                    [s.paddingHalf]: headCell.dataCell?.padding === 'half',
                    [s.noPaddingLeft]:
                      headCell.dataCell?.connected &&
                      ['left', 'middle'].includes(headCell.dataCell.connected),
                    [s.noPaddingRight]:
                      headCell.dataCell?.connected &&
                      ['middle', 'right'].includes(headCell.dataCell.connected),
                    [s.shrinkToContent]: headCell.dataCell?.shrink === 'content',
                  });

                  var cellContent = (
                    <>
                      {actionsDataCell?.function && headCell.isActionsCell ? (
                        <actionsDataCell.function
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={sorted}
                        ></actionsDataCell.function>
                      ) : undefined}
                      {headCell.transformDataCell ? (
                        <headCell.transformDataCell
                          data={row[headCell.id]}
                          row={row}
                          order={order}
                          sorted={sorted}
                        ></headCell.transformDataCell>
                      ) : headCell.transformFunction ? (
                        headCell.transformFunction(row[headCell.id], row, order, sorted)
                      ) : (
                        row[headCell.id]
                      )}
                    </>
                  );

                  if (headCell.hierarchy) {
                    cellContent = (
                      <div
                        style={{
                          display: 'flex',
                          whiteSpace: 'nowrap',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            paddingLeft: (20 * row?.level).toString() + 'px',
                          }}
                        ></div>
                        <div>{cellContent}</div>
                        {!!isExpandedRow ? (
                          <div
                            style={{
                              paddingLeft: '13px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={(event: any) => handleOnExpandChange(event, row.id.toString())}
                          >
                            <ExpandLessIcon fontSize="small" style={{ opacity: '.7' }} />
                          </div>
                        ) : undefined}
                        {isExpandedRow === false ? (
                          <div
                            style={{
                              paddingLeft: '15px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            onClick={(event: any) => handleOnExpandChange(event, row.id.toString())}
                          >
                            <ExpandMoreIcon fontSize="small" style={{ opacity: '.7' }} />
                          </div>
                        ) : undefined}
                      </div>
                    );
                  }

                  if (headCell.hierarchyActions) {
                    cellContent = (
                      <div
                        style={{
                          display: 'flex',
                          whiteSpace: 'nowrap',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {toShowArrowLeft ? (
                          <div
                            style={{
                              padding: '0 4px',
                              cursor: 'pointer',
                            }}
                            onClick={(event: any) => handleOnArrowLeft(event, row.id.toString())}
                          >
                            <ArrowLeftIcon fontSize="small" style={{ opacity: '.7' }} />
                          </div>
                        ) : undefined}
                        <div>{cellContent}</div>
                        {toShowArrowRight ? (
                          <div
                            style={{
                              padding: '0 4px',
                              cursor: 'pointer',
                            }}
                            onClick={(event: any) => handleOnArrowRight(event, row.id.toString())}
                          >
                            <ArrowRightIcon fontSize="small" style={{ opacity: '.7' }} />
                          </div>
                        ) : undefined}
                      </div>
                    );
                  }

                  if (count === 1)
                    columns.push(
                      <TableCell
                        component="th"
                        id={`tc-${index}`}
                        scope="row"
                        padding="none"
                        key={count.toString() + '_' + headCell.id}
                        align={headCell.dataCell?.align}
                        colSpan={headCell.colSpan}
                        className={cellClassName}
                        title={
                          headCell.dataCell?.wrap === 'box'
                            ? headCell.transformFunction
                              ? headCell.transformFunction(row[headCell.id], row, order, sorted)
                              : row[headCell.id]
                            : undefined
                        }
                      >
                        {cellContent}
                      </TableCell>
                    );
                  else {
                    columns.push(
                      <TableCell
                        align={headCell.dataCell?.align || 'left'}
                        key={count.toString() + '_' + headCell.id}
                        colSpan={headCell.colSpan}
                        className={cellClassName}
                        title={
                          headCell.dataCell?.wrap === 'box'
                            ? headCell.transformFunction
                              ? headCell.transformFunction(row[headCell.id], row, order, sorted)
                              : row[headCell.id]
                            : undefined
                        }
                      >
                        {cellContent}
                      </TableCell>
                    );
                  }
                });
              }
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  onClick={debounce(
                    (event: any) => handleClick(event, row.id.toString()),
                    DEBOUNCE_TIMEOUT
                  )}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {[
                    draggable ? (
                      <TableCell
                        align="left"
                        style={{ width: '20px', padding: '0 16px' }}
                        key={'Draggable'}
                      >
                        <div
                          style={{
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ padding: '0 16px 0 0' }}>
                            {Number.isInteger(row.index)
                              ? row.index + 1
                              : index + page * rowsPerPage + 1}
                            .
                          </div>
                        </div>
                      </TableCell>
                    ) : undefined,
                    ...columns,
                  ]}
                </TableRow>
              );
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {!hidePagination ? (
        <Pagination
          rowsPerPageOptions={TABLE_PAGINATION}
          count={totalItems}
          totalCount={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          leftSideComponent={paginationSideComponent}
          {...paginationProps}
        ></Pagination>
      ) : undefined}
    </div>
  );
};
