import { Badge, Divider, Menu, MenuItem, styled, TableSortLabel } from '@mui/material';
import cx from 'classnames';
import React, { FC, useCallback } from 'react';
import styles from './style.module.scss';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface IHeaderCell {
  label: string;
  id?: string;
  sortable?: boolean;
  sort?: SortOrder;
  sortOrder?: number; // undefined, 1, 2, 3
  onSortChange?: (e: any) => void;
  onSetSort: (x: { sort: SortOrder; sortOrder: number } | undefined) => void;
  orderLength: number;
}

export const HeaderCell: FC<IHeaderCell> = ({
  label,
  id,
  sortable,
  sort,
  sortOrder,
  onSortChange,
  onSetSort,
  orderLength,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const open = Boolean(anchorEl);

  const handleOpenContextClick = useCallback((e: Record<string, any>): void => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const headerClass = cx(styles.header, {
    [styles.sortable]: sortable,
    [styles.nonsortable]: !sortable,
  });

  const _sort = sort ? (sort.toLowerCase() as 'asc' | 'desc') : 'asc';

  if (sortable) {
    return (
      <div className={headerClass}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          // getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {sortOrder || !orderLength ? (
            <>
              <MenuItem
                selected={_sort === 'asc' && !!sortOrder}
                onClick={() => {
                  onSetSort({ sort: SortOrder.ASC, sortOrder: sortOrder ?? 1 });
                  handleClose();
                }}
              >
                Sort Ascending
              </MenuItem>
              <MenuItem
                selected={_sort === 'desc' && !!sortOrder}
                onClick={() => {
                  onSetSort({ sort: SortOrder.DESC, sortOrder: sortOrder ?? 1 });
                  handleClose();
                }}
              >
                Sort Descending
              </MenuItem>
            </>
          ) : undefined}
          {sortOrder && orderLength > 1 ? (
            <MenuItem disabled={true}>
              <Divider />
            </MenuItem>
          ) : undefined}
          {orderLength && !(orderLength === 1 && sortOrder) ? (
            <MenuItem
              onClick={() => {
                onSetSort({ sort: sort ?? SortOrder.ASC, sortOrder: 1 });
                handleClose();
              }}
              selected={sortOrder === 1}
            >
              Primary Sort
            </MenuItem>
          ) : undefined}
          {orderLength && !(orderLength === 1 && sortOrder) ? (
            <MenuItem
              selected={sortOrder === 2}
              onClick={() => {
                onSetSort({ sort: sort ?? SortOrder.ASC, sortOrder: 2 });
                handleClose();
              }}
            >
              Secondary Sort
            </MenuItem>
          ) : undefined}
          {orderLength > 1 && !(orderLength === 2 && sortOrder) ? (
            <MenuItem
              selected={sortOrder === 3}
              onClick={() => {
                onSetSort({ sort: sort ?? SortOrder.ASC, sortOrder: 3 });
                handleClose();
              }}
            >
              Tertiary Sort
            </MenuItem>
          ) : undefined}
          {orderLength > 2 && !(orderLength === 3 && sortOrder) ? (
            <MenuItem
              selected={sortOrder === 4}
              onClick={() => {
                onSetSort({ sort: sort ?? SortOrder.ASC, sortOrder: 4 });
                handleClose();
              }}
            >
              Fourth Sort
            </MenuItem>
          ) : undefined}
          {orderLength > 3 && !(orderLength === 4 && sortOrder) ? (
            <MenuItem
              selected={sortOrder === 5}
              onClick={() => {
                onSetSort({ sort: sort ?? SortOrder.ASC, sortOrder: 5 });
                handleClose();
              }}
            >
              Fifth Sort
            </MenuItem>
          ) : undefined}

          {sortOrder ? (
            <>
              <MenuItem disabled={true}>
                <Divider />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetSort(undefined);
                  handleClose();
                }}
              >
                Remove from Sort
              </MenuItem>
            </>
          ) : undefined}
          {orderLength && orderLength > 1 ? (
            <>
              <MenuItem disabled={true}>
                <Divider />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onSetSort({ sort: SortOrder.ASC, sortOrder: -1 });
                  handleClose();
                }}
              >
                Clear All
              </MenuItem>
            </>
          ) : undefined}
        </Menu>
        <CustomStyledBadge
          badgeContent={sortOrder ? `${sortOrder}.` : ''}
          // color="primary"
          invisible={!sortOrder || orderLength === 1}
        >
          <TableSortLabel
            active={Boolean(sort)}
            direction={_sort}
            onClick={onSortChange}
            onContextMenu={handleOpenContextClick}
          >
            {<div className={styles.headerText}>{label}</div>}
          </TableSortLabel>
        </CustomStyledBadge>
      </div>
    );
  }

  return (
    <div className={headerClass}>
      <span className={styles.headerText}>{label}</span>
      <div className="MuiSvgIcon-root" style={{ width: '8px' }}></div>
    </div>
  );
};

const CustomStyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    backgroundColor: 'lightgray',
    color: 'grey',
    fontSize: '0.6rem', // Adjust font size here
    height: '16px', // Adjust height if needed
    minWidth: '20px', // Adjust width if needed
  },
});
